import { GlobalConstants } from '../constants/global-constants';

// eslint-disable-next-line default-param-last
export function GlobalReducers(state = {}, action) {
  switch (action.type) {
    case GlobalConstants.UPDATE_USER_DATA:
      return {
        ...state,
        iUserData: action.payload,
      };
    case GlobalConstants.UPDATE_BOT_USER_DATA:
      return {
        ...state,
        botUserData: action.payload,
      };
    case GlobalConstants.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case GlobalConstants.HANDLE_GENERAL_DIALOG:
      return {
        ...state,
        generalDialog: { ...state.generalDialog, ...action.payload },
      };
    case GlobalConstants.CUSTOM_DIALOG_CONFIG:
      return {
        ...state,
        modalConfig: action.payload,
      };
    case GlobalConstants.CATEGORIES_GET_SUCCESS:
      return {
        ...state,
        categories: action.payload,
      };
    case GlobalConstants.SET_LOCALE:
      return {
        ...state,
        locale: action.payload,
      };
    case GlobalConstants.NOTHING:
      return {
        ...state,
        nothing: action.payload,
      };
    default:
      return state;
  }
}
