export const GlobalConstants = {
  UPDATE_USER_DATA: 'UPDATE_USER_DATA',
  UPDATE_BOT_USER_DATA: 'UPDATE_BOT_USER_DATA',
  HANDLE_GENERAL_DIALOG: 'HANDLE_GENERAL_DIALOG',
  SET_IS_LOADING: 'SET_IS_LOADING',
  CUSTOM_DIALOG_CONFIG: 'CUSTOM_DIALOG_CONFIG',
  CATEGORIES_GET_SUCCESS: 'CATEGORIES_GET_SUCCESS',
  SET_LOCALE: 'SET_LOCALE',
  NOTHING: 'NOTHING',
};
