import { AdminConstants } from '../constants/admin-constants';

// eslint-disable-next-line default-param-last
export function AdminReducers(state = {}, action) {
  switch (action.type) {
    case AdminConstants.GET_ALL_USERS:
      return {
        ...state,
        users: action.payload,
      };

    case AdminConstants.GET_ALL_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case AdminConstants.GET_ALL_MAKES:
      return {
        ...state,
        makes: action.payload,
      };
    case AdminConstants.GET_ALL_LOCATIONS:
      return {
        ...state,
        locations: action.payload,
      };
    case AdminConstants.GET_ALL_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    case AdminConstants.GET_ALL_GALLERY:
      return {
        ...state,
        gallery: action.payload,
      };

    default:
      return state;
  }
}
