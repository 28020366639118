import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import SuspenseLoader from '../components/SuspenseLoader';
import { PrivateRoute } from './private-routes';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const BaseLayout = Loader(
  // eslint-disable-next-line import/no-cycle
  lazy(() => import('../theme/layouts/BaseLayout')),
);
const EditProfilePage = Loader(
  // eslint-disable-next-line import/no-cycle
  lazy(() => import('../pages/client/edit-profile-page')),
);
const SidebarLayout = Loader(
  // eslint-disable-next-line import/no-cycle
  lazy(() => import('../theme/layouts/SidebarLayout')),
);
// Pages

const Home = Loader(
  // eslint-disable-next-line import/no-cycle
  lazy(() => import('../pages/client/home')),
);
const ContactPage = Loader(
  // eslint-disable-next-line import/no-cycle
  lazy(() => import('../pages/client/contact')),
);
const ItemsList = Loader(
  // eslint-disable-next-line import/no-cycle
  lazy(() => import('../pages/client/items-list')),
);
const Details = Loader(lazy(() => import('../pages/client/details')));
const Login2 = Loader(
  // eslint-disable-next-line import/no-cycle
  lazy(() => import('../pages/client/login-page2')),
);
const CategoriesList = Loader(lazy(() => import('../pages/admin/categories-list-page')));
const MakesList = Loader(lazy(() => import('../pages/admin/makes-list-page')));

const LocationsList = Loader(lazy(() => import('../pages/admin/locations-list-page')));
const MachinesList = Loader(lazy(() => import('../pages/admin/machines-list-page')));
const MachineGallery = Loader(lazy(() => import('../pages/admin/machine-gallery-page')));
const UsersList = Loader(lazy(() => import('../pages/admin/users-list-page')));
const mlRoutes = () => {
  return (
    <Route>
      <Route path="/" element={<BaseLayout />}>
        <Route path="" element={<Home />} />
        <Route path="items/details/:ProductId" element={<Details />} />
        <Route path="login" element={<Login2 />} />
        <Route path="contact" element={<ContactPage />} />
        <Route path="edit-profile" element={<PrivateRoute element={<EditProfilePage />} />} />
        <Route path="items-list" element={<ItemsList />} />
      </Route>
      <Route element={<SidebarLayout />}>
        {/*<Route path="symbols/:symbol" element={<TechnicalPage />} />*/}
        <Route path="acp">
          <Route path="acp" element={<PrivateRoute element={<Navigate to="/acp/dashboard" replace />} />} />

          <Route path="categories" element={<PrivateRoute element={<CategoriesList />} />} />
          <Route path="makes" element={<PrivateRoute element={<MakesList />} />} />

          <Route path="locations" element={<PrivateRoute element={<LocationsList />} />} />
          <Route path="users" element={<PrivateRoute element={<UsersList />} />} />
          <Route path="machines" element={<PrivateRoute element={<MachinesList />} />} />
          <Route path="machine-gallery/:productId" element={<PrivateRoute element={<MachineGallery />} />} />
        </Route>
      </Route>
      {/* </Route> */}
    </Route>
  );
};

class AppRouter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/">{mlRoutes()}</Route>
        </Routes>
      </BrowserRouter>
    );
  }
}

// };

export default AppRouter;
