// import { createStore, applyMiddleware, compose } from 'redux';
// import thunkMiddleware from 'redux-thunk';
// import rootReducer from '../reducers';
//
// const middleware = [thunkMiddleware];
//
// const configureStore = () => {
//   return createStore(rootReducer, compose(applyMiddleware(...middleware)));
// };
//
// export default configureStore;
//
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../reducers';

const middleware = [thunkMiddleware];
const logger = createLogger({
  // ...options
});
const configureStore = () => {
  return createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleware, logger)));
};
export default configureStore;
