export const setLS = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const getLS = (key) => {
  const value = window.localStorage.getItem(key);
  return JSON.parse(value);
};

export const removeLS = (key) => {
  window.localStorage.removeItem(key);
};

export const avatar = (fileName) => {
  return 'https://mrt.pro/images/profile-photo/' + fileName;
};
export const cover = (fileName) => {
  return 'https://mrt.pro/images/profile-cover/' + fileName;
};

export const productDefImg = (productId) => {
  return `https://mrt.pro/images/products/${productId}/default.png`;
};

export const productImg = (item) => {
  return `https://mrt.pro/images/products/${item.product}/${item.file}`;
};

export const badgePhoto = (fileName) => {
  return `https://mrt.pro/images/badges/${fileName}`;
};

export const questionPhoto = (fileName) => {
  return `https://mrt.pro/images/questions/${fileName}`;
};

export const idCard = (fileName) => {
  return `https://mrt.pro/images/id-card/${fileName}`;
};

export const slider = (fileName) => {
  return `https://mrt.pro/images/sliders/${fileName}`;
};
export const blogFeaturedImage = (fileName) => {
  return `https://mrt.pro/images/blog-posts/featured-image/${fileName}`;
};

export const localeUrl = (url, locale) => (locale ? `/${locale}${url}` : url);
