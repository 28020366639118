import App from './App';

import { HelmetProvider } from 'react-helmet-async';
import * as serviceWorker from './serviceWorker';
import 'nprogress/nprogress.css';

import { SidebarProvider } from './providers/SidebarContext';

import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <HelmetProvider>
    <SidebarProvider>
      {/* <BrowserRouter> */}
      <App />
      {/* </BrowserRouter> */}
    </SidebarProvider>
  </HelmetProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

serviceWorker.unregister();
